<template>
  <b-row class="m-3">
    <b-col>
      <b-card-group deck>
        <b-card class="border-0">
          The Member Portal allows members to access important information about class schedules and time cards. Training Centers have the option to
          publish their current class schedule. Members can view the schedule and submit a registration request for classes being offered at their
          Local Training Center, and download a copy of their Training History. Apprentices also have the option to track the classes they must attend
          to complete the apprenticeship, as well as record and review On‐Job‐Training hours through this website.
        </b-card>
        <b-card bg-variant="light" @keyup.enter="login" no-body v-if="authChecked">
          <b-card-body>
            <b-form-group label="Identity:">
              <b-input size="sm" type="text" placeholder="Identity" v-model="identity" trim />
            </b-form-group>
            <b-form-group label="Password:">
              <b-input-group>
                <b-input size="sm" :type="passwordVisible ? 'text' : 'password'" placeholder="Password" v-model="password" />
                <b-input-group-addon>
                  <b-button size="sm" @click="passwordVisible = !passwordVisible">
                    <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" />
                  </b-button>
                </b-input-group-addon>
              </b-input-group>
            </b-form-group>
            <b-form-group class="text-right">
              <b-button size="sm" variant="primary" @click="login">Log In</b-button>
            </b-form-group>
          </b-card-body>
        </b-card>
        <b-card bg-variant="light" no-body v-else>
          <b-card-body>
            <h3>Validating your session ... Please wait.</h3>
          </b-card-body>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({
  metaInfo: {
    title: 'Member Portal - Log In'
  }
})
export default class LoginForm extends Vue {
  passwordVisible = false;

  identity = null;
  password = null;

  get authChecked() {
    return this.$store.getters['session/authChecked'];
  }

  get desiredRoute() {
    return this.$store.getters.desiredRoute;
  }

  set desiredRoute(route) {
    this.$store.dispatch('redirectSecureRoute', route);
  }

  async login() {
    try {
      const credentials = {
        identity: (this.identity || '').trim(),
        password: (this.password || '').trim()
      };

      if (0 === credentials.identity.length) {
        return;
      }

      const redirectUrl = this.$route?.query?.redirect;
      credentials.redirect = !!redirectUrl;

      const user = await this.$store.dispatch('session/login', credentials);

      if (credentials.redirect) {
        return (document.location = redirectUrl);
      }

      await this.hydrateUserAndRedirect(user);
    } catch (error) {
      const message = error && error.message ? error.message : 'An unknown error occurred, please try again';

      this.$bvToast.toast(message, {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    }
  }

  async hydrateUserAndRedirect(user) {
    if (user && user.ubcId) {
      this.$store.dispatch('loadCurrentMember', { ubcId: user.ubcId, override: true });
      this.$store.dispatch('loadTrainingCenter', { ubcId: user.ubcId, override: true });
      this.$store.dispatch('loadMemberPicture', { ubcId: user.ubcId, override: true });
      const route = this.desiredRoute;
      if (route) {
        this.desiredRoute = null;
        this.$router.push(route);
      } else {
        this.$router.push({ name: 'training', params: { ubcId: user.ubcId } });
      }
    }
  }

  async validateSession() {
    const user = await this.$store.dispatch('session/validateCookie', { override: true, route: this.$route });
    await this.hydrateUserAndRedirect(user);
  }
}
</script>
<style scoped></style>
